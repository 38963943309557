import "../App.css";
import NumberBox from "../components/NumberBox";
import { useEffect, useState, useContext } from "react";
import Team1 from "../components/Team1";
import Team2 from "../components/Team2";
import {
  FaRandom,
  FaTimes,
  FaEye,
  FaEyeSlash,
  FaSignOutAlt,
  FaForward,
} from "react-icons/fa";
import Confetti from "react-confetti";
import dancingGif from "../assets/gifs/dancing.gif";
import AdminDashboard from "../components/AdminDashboard";
import { SongsContext } from "../context/SongsContext";
import SpotifyPlayer from "react-spotify-web-playback";
import { useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateSessionData,
} from "firebase/firestore";
import { db } from "../Firebase";
import NumberBoxMobile from "../components/NumberBoxMobile";
import Team1Mobile from "../components/Team1Mobile";
import Team2Mobile from "../components/Team2Mobile";

const ControlDashboard = ({
  sessionData,
  endGame,
  words,
  title,
  redNumbers,
  updateSong,
  updateSessionData,
  updateMaxScore,
  artist,
  updateShowBoxes,
}) => {
  
  const showCountdown = () => {
    updateSessionData({ showCountdown: true });
  }

  const setShowWinner = (value) => {
    updateSessionData({ showWinner: value });
  }


  return (
    // <div>Hall ControlDashboard</div>
    <>
      {!sessionData.songList ? (
        <div style={{ color: "white" }}>Loading..</div>
      ) : (
        <header
          className="App-headerMobile"
          style={{ height: "100vh", backgroundColor: "#23262d" }}
        >
          <div
            className="infoHeader"
            style={{
              fontSize: 14,
              width: "100vw",
              height: "5vh",
              backgroundColor: "#3d3d5b",
              borderBottomRightRadius: 20,
              borderBottomLeftRadius: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                cursor: "pointer",
                padding: 5,
                //backgroundColor: "red",
                height: "40%",
                width: "10%",
                borderRadius: 5,
                color: "red",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                //border: "1px solid red"
              }}
              onClick={() => {
                let shouldCancel = window.confirm("Avslutte spillet?");
                if (shouldCancel) {
                  endGame();
                } else {
                  return;
                }
              }}
            >
              <FaSignOutAlt color="red" />
              <div style={{ marginLeft: 5 }}>Avslutt spill</div>
            </div>
            <div
              style={{
                padding: 5,
                height: "40%",
                width: "40%",
              }}
            >
              {sessionData.currentIndex + 1}/
              {sessionData.songList?.songs?.length} sanger
            </div>
            {/*}
                        <div
                            style={{
                                cursor: "pointer",
                                padding: 5,
                                //backgroundColor: "red",
                                height: "40%",
                                width: "10%",
                                borderRadius: 5,
                                //border: "1px solid white"
                            }}
                            onClick={() => {

                            }}
                        >
                            Regler
                        </div>
                        */}
            <div
              style={{
                width: "10%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "flex-end",
                userSelect: "none",
                textAlign: "center",
                marginRight: "2vw",
              }}
            >
              Først til
              <div
                style={{ marginLeft: 7 }}
                contentEditable="true"
                onInput={(e) =>
                  updateMaxScore(Number(e.currentTarget.textContent))
                }
              >
                {" "}
                {sessionData.maxScore}
              </div>
            </div>
          </div>

          <div
            //className="allNumbers"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              //minWidth: '80vw',
              //marginLeft: '2.5%',
              height: "25vh",
              //backgroundColor: 'white',
              alignItems: "center",
              justifyContent: "center",
              //backgroundColor: "#23262d",
              marginTop: "2vh",
              borderRadius: 10,
              //overflow: "hidden",
            }}
          >
            {words.map((word, i, words) => {
              return (
                <NumberBoxMobile
                  i={i}
                  key={word + title + i}
                  word={word}
                  backgroundColor={
                    sessionData.redNumbers.includes(i) ? "red" : "blue"
                  }
                  words={words}
                  showAnswer={sessionData.showAnswer}
                  //updateShowBoxes={updateShowBoxes}
                  showBoxes={sessionData.showBoxes}
                  updateSessionData={updateSessionData}
                  updateShowBoxes={updateShowBoxes}
                />
              );
            })}
          </div>
          <div style={{ height: "15vh", width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  //minHeight: '20vh',
                  //maxHeight: '40vh',
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* {sessionData.showAnswer && ( */}
                {true && (
                  <div
                    style={{
                      marginTop: 30,
                      color: "white",
                      fontFamily: "Roboto",
                      height: "70%",
                      minWidth: "30vw",
                      fontSize: "3vh",
                    }}
                  >
                    <div>{title.toUpperCase()}</div>
                    <div>{artist}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              height: "10vh",
              width: "95%",
              //padding: '1vh',
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "80%",
                height: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {sessionData.showCountdown && (
                <>
                  <iframe
                    className="video"
                    width={"100%"}
                    height="100%"
                    title="Youtube player"
                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                    src={`https://youtube.com/embed/8tAXbkGR_-c?start=9`}
                  ></iframe>
                  <div
                    style={{ cursor: "pointer", marginTop: "1vh" }}
                    onClick={() => updateSessionData({ showCountdown: false })}
                  >
                    <FaTimes />
                  </div>
                </>
              )}
              {sessionData.showWinner && (
                <img
                  className="App-logo"
                  height="100%"
                  src={dancingGif}
                  alt="dancing"
                />
              )}
              {!sessionData.showWinner && !sessionData.showCountdown && (
                <div
                  className="button"
                  style={{
                    justifyContent: "space-around",
                    display: "flex",
                    height: "8vh",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "0px solid white",
                    borderRadius: 10,
                    marginBottom: "2vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "space-around",
                      width: "100%",
                    }}
                  >
                    {!sessionData.showAnswer && (
                      <div
                        style={{
                          flexDirection: "row",
                          width: "90%",
                          color: "white",
                          cursor: "pointer",
                          userSelect: "none",
                          //margin: "2vh",
                          //marginTop: "4vh",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                        // onClick={showAnswerOnScreen}
                        onClick={() => updateSessionData({ showAnswer: true })}
                        //Show answer on screen
                      >
                        <FaEye size="5vh" />
                        <div style={{ marginLeft: "1vw", fontSize: 30 }}>
                          Vis fasit
                        </div>
                      </div>
                    )}
                    {sessionData.showAnswer && (
                      <div
                        style={{
                          flexDirection: "row",
                          width: "90%",
                          color: "white",
                          cursor: "pointer",
                          userSelect: "none",
                          //margin: "2vh",
                          //marginTop: "4vh",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                        onClick={() => {
                          //setNumberOfSongs(numberOfSongs + 1);
                          updateSong();
                        }}
                      >
                        <FaForward size="5vh" />
                        <div style={{ marginLeft: "1vw", fontSize: 30 }}>
                          Neste sang
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {sessionData.showWinner && (
                <div
                  style={{
                    cursor: "pointer",
                    marginTop: "1vh",
                    padding: "1vh",
                  }}
                  onClick={() => updateSessionData({ showWinner: false })}
                >
                  <FaTimes />
                </div>
              )}
            </div>
          </div>
          <div
            className="bottomBar"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              minHeight: "35vh",
              maxHeight: "35vh",
              //overflow: "hidden",
              width: "100%",
              borderTopLeftRadius: 50,
              borderTopRightRadius: 50,
              paddingTop: "1vh",
              // marginBottom: "5vh",
              position: "absolute",
              bottom: 0,
            }}
          >
            <Team1Mobile
              //setWinner={setWinner}
              score={sessionData.team1.score}
              i={"1"}
              maxScore={sessionData.maxScore}
              showWinner={sessionData.showWinner}
              setShowWinner={setShowWinner}
              setShowCountdown={showCountdown}
              showCountdown={sessionData.showCountdown}
              initTeamName={sessionData.team1.name}
              updateScore={(newScore) =>
                updateSessionData({ "team1.score": newScore })
              }
              updateSessionData={updateSessionData}
            />

            <div
              style={{
                width: 2,
                height: "20vh",
                backgroundColor: "white",
                alignSelf: "center",
              }}
            />
            <Team2Mobile
              //setWinner={setWinner}
              score={sessionData.team2.score}
              i="2"
              maxScore={sessionData.maxScore}
              showWinner={sessionData.showWinner}
              setShowWinner={setShowWinner}
              setShowCountdown={showCountdown}
              showCountdown={sessionData.showCountdown}
              initTeamName={sessionData.team2.name}
              updateScore={(newScore) =>
                updateSessionData({ "team2.score": newScore })
              }
              updateSessionData={updateSessionData}
            />
          </div>
        </header>
      )}
    </>
  );
};

export default ControlDashboard;
